.air-drop {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
    border-radius: 8px;
    position: relative;
    margin: 0 10px 0 30px;
    white-space: nowrap;
    cursor: pointer;
    padding: 5px 0px;

    @media (max-width: 1200px) {
        margin: 0;
        margin-right: 10px;
        padding: 0px 0px 0px 3px;
        // width: 100%;
        border-top: 1px solid rgba(255, 255, 255, 0.08);
        justify-content: flex-start;
    }

    &-text {
        padding: 8px 30px 8px 30px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff !important;
    }
}

.air-drop-modal {
    border-radius: 8px;
    padding: 10px;
    max-width: 370px;
    color: white;
    max-width: 570px;

    .modal-body {
        border-radius: 8px;
        padding: 10px;
        background: url("../../../assets/images/bg-air-drop.png") center;

        .top-body {
            text-align: right;

            .icon-cancel {
                color: #f3574f;
                cursor: pointer;
            }
        }

        .body {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 30px;

            .announcement {
                font-weight: bold;
                font-size: 24px;
                color: #f6f7fb;
                margin-bottom: 15px;
                text-align: center;
            }

            .airi {
                width: 60px;
                height: 60px;
                margin-bottom: 25px;
            }

            .amount {
                font-weight: bold;
                font-size: 48px;
                text-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
                background: -webkit-linear-gradient(rgba(0, 255, 194, 1), rgba(0, 209, 255, 1));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 23px;
            }

            .title {
                font-weight: bold;
                font-size: 24px;
                color: #f6f7fb;
                margin-bottom: 10px;
            }

            .description {
                display: inline-block;
                font-weight: 600;
                font-size: 16px;
                color: #f6f7fb;
                margin-bottom: 30px;

                &-text {
                }

                .community-link {
                    cursor: pointer;
                }
            }

            .button {
                height: 40px;
                width: 340px;
                background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
                border-radius: 4px;
                color: #f6f7fb;
                padding: 8px;
                text-align: center;
                border: none;
                margin-bottom: 15px;
                cursor: pointer;
            }
        }
    }
}
