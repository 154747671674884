.landing {
    background: linear-gradient(to right, #181818 0%, #0a212c 100%), linear-gradient(180deg, #0a394e 0%, #2b0a36 100%);
}

.check-nft {
    @media (max-width: 600px) {
        padding-top: 20px;

        :global(.container) {
            flex-direction: column;
        }
    }

    padding-top: 60px;

    &-button {
        display: inline-block;
        padding: 12px 42px;
        background: #1a87ff;
        border-radius: 40px;
        color: #ffffff;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        cursor: pointer;
    }

    &-bg {
        @media (max-width: 600px) {
            width: 100%;
            padding-left: 20px;
        }

        background-size: auto 100%;
        padding-top: 20px;
        padding-left: 100px;
        height: fit-content;
        width: 550px;
        background-image: url("/assets/images/landing/image-1.png");
        background-repeat: no-repeat;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.statistic {
    display: flex;
    margin: 30px 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-icon {
        width: 120px;
        height: 100px;
    }

    &-list {
        margin-left: 15px;
        padding: 0;
        list-style: none;

        & > li {
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            margin: 12px 0;
            color: #ffffff;
        }
    }

    &-number {
        color: #a1f0fa;
        margin-left: 5px;
    }
}

.about {
    background-image: url("/assets/images/landing/info.png");
    background-repeat: no-repeat;
    background-position: 0 -205px;
    border-radius: 8px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 40px;
    margin: 10px auto 60px auto;

    @media (min-width: 950px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 230px;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        @media (min-width: 950px) {
            align-items: flex-start;
        }

        &-value {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 56px;
            line-height: 100%;

            @media (min-width: 950px) {
                line-height: 150%;
            }

            .metamask-icon {
                width: 25px;
                height: 25px;
                margin-left: 10px;
            }
        }

        &-title {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
        }
    }

    .info-loading {
        align-items: center;
    }

    .buy-token {
        width: 159px;
        height: 50px;
        background: linear-gradient(135deg, #32d8fc 0%, #2137ff 100%);
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &-text {
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 150%;
            color: #ffffff;
        }

        &-icon {
            margin-left: 7px;
        }
    }
}

.buy-token-airi {
    min-width: 94px;
    height: 38px;
    padding: 6px 10px 6px 10px;
    margin: 0px 10px 0px 20px;
    text-align: center;
    // background: linear-gradient(135deg, #32D8FC 0%, #2137FF 100%);
    background: linear-gradient(99.99deg, #0056e8 0%, #009cfd 98.89%);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
    }

    &-icon {
        margin-left: 7px;
    }
}

.service-title {
    margin: 120px auto 60px auto;
    width: 300px;
    height: 300px;
    max-width: 100%;
    text-align: center;
    color: #a1f0fa;
    font-size: 36px;
    line-height: 300px;
    font-weight: 700;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url("/assets/images/rotate-bg.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        animation: spin 10s linear infinite;
    }
}

.introduce {
    @media (max-width: 600px) {
        width: 100%;
    }

    display: flex;
    flex-direction: column;
    width: 470px;
    height: 100%;
    padding-bottom: 60px;

    &-title {
        margin-top: 32px;
        color: #ffffff;
        background-color: #252323;
        width: 167px;
        height: 60px;
        text-align: center;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 60px;
        letter-spacing: -0.02em;
        transform: skew(-8deg);

        @media (min-width: 992px) {
            margin-top: 0;
        }
    }

    &-description {
        margin-top: 20px;
        color: #fff;
        font-family: Source Sans Pro;
        font-size: 36px;
        line-height: 150%;
    }

    &-large {
        @media (max-width: 600px) {
            font-size: 44px;
        }

        margin-top: 0;
        margin-bottom: 10px;
        color: #a1f0fa;
        font-size: 56px;
        font-weight: bold;
    }

    &-small {
        margin-top: 20px;
        font-size: 20px;
    }
}

.comming {
    position: relative;
    background-image: url("/assets/images/landing/image-2.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;

    &-title {
        color: #fff;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 46px;
        line-height: 130%;
    }

    &-inner {
        @media (max-width: 600px) {
            flex-direction: column;
            padding: 0;
        }

        display: flex;
        justify-content: space-between;
        z-index: 1;
    }

    &-content {
        max-width: 100%;
        width: 806px;
        flex-wrap: wrap;
    }
}

.feature.special {
    height: 466px;
    justify-content: flex-start;

    @media (max-width: 600px) {
        justify-content: space-between;
        display: block;
    }
}

.feature {
    @media (max-width: 600px) {
        margin: 20px;
        width: calc(100vw - 40px) !important;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    margin-right: 40px;
    margin-bottom: 30px;
    padding: 20px 40px 10px 40px;
    width: 360px;
    max-width: 100%;
    height: 218px;
    transition: background-color 0.5s;

    &:hover,
    &:active {
        background: rgba(0, 0, 0, 1);
    }

    &-title {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        cursor: pointer;

        &-icon {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            color: #81d1ff;
            font-size: 16px;

            &-box {
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background: rgba(129, 209, 255, 0.1);
                border-radius: 50%;
                margin-right: 10px;
                margin-bottom: 10px;
            }

            &-text {
                margin-left: 5px;
                margin-bottom: 10px;
            }
        }

        &-text {
            flex-grow: 1;
            color: #fff;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 150%;

            &-active {
                color: #81d1ff;
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 150%;
            }
        }
    }

    &-description {
        color: #eaeaea;
        font-family: Source Sans Pro;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
    }
}

.bottom-bg {
    margin-top: -300px;
    height: 390px;
    width: 100%;
    background: linear-gradient(180deg, transparent 0%, #1b1826 98%);
    filter: blur(4px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.price-loading {
    svg {
        animation: rotate 2.2s linear infinite;
        -webkit-animation: rotate 2.2s linear infinite;
    }
}

.banner-dialog {
    max-width: 100vw;
    max-height: 100vh;
    :global(.MuiPaper-root) {
        max-height: 800px;
        max-width: 951px;
        border-radius: 43px;
        @media (max-width: 962px) {
            max-height: 650px;
        }

        @media (max-width: 768px) {
            max-height: 600px;
        }

        @media (max-width: 500px) {
            max-height: 500px;
        }

        @media (max-width: 375px) {
            max-height: 400px;
        }

        overflow: visible;
        box-shadow: 0 -3px 5px 0 rgba(255, 255, 255, 0.6), 0 6px 10px 0 rgba(255, 255, 255, 0.6);
    }

    .popup-banner {
        background: transparent;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        &-close {
            position: absolute;
            cursor: pointer;
            width: 39px;
            margin-right: auto;
            right: 33px;
            top: 31px;

            @media (max-width: 962px) {
                position: absolute;
                width: 34px;
                margin-right: auto;
                right: 26px;
                top: 25px;
                cursor: pointer;
            }

            .close-icon {
                width: 100%;
            }
        }

        .button-wrapper {
            margin-top: 15px;
            position: absolute;
            bottom: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (max-width: 962px) {
                bottom: 100px;
            }

            @media (max-width: 768px) {
                // max-height: 600px;
            }

            @media (max-width: 500px) {
                bottom: 80px;
            }

            @media (max-width: 375px) {
                bottom: 65px;
                max-height: 400px;
            }

            .image {
                width: 215px;
                max-height: 51px;
                cursor: pointer;

                @media (max-width: 375px) {
                    width: 150px;
                }
            }

            .text-wrapper {
                margin-top: 15px;
                display: flex;
                align-items: center;
                cursor: pointer;
                height: 18px;

                @media (max-width: 962px) {
                    // bottom: 100px;;
                }

                @media (max-width: 768px) {
                    // max-height: 600px;
                }

                @media (max-width: 500px) {
                    font-size: 13px;
                    height: 13px;
                }

                @media (max-width: 375px) {
                    height: 11px;
                    font-size: 11px;
                }

                .icon {
                    margin-right: 4px;
                    height: inherit;
                }

                .text {
                    color: #b8b5b5;
                    font-family: Source Sans Pro;
                    font-style: normal;
                    font-weight: 400;
                    font-size: inherit;
                    line-height: 150%;
                }
            }
        }

        &-image {
            max-height: 800px;
            max-width: 951px;
            border-radius: 43px;
            @media (max-width: 962px) {
                max-height: 650px;
            }

            @media (max-width: 768px) {
                max-height: 600px;
            }

            @media (max-width: 500px) {
                max-height: 500px;
            }

            @media (max-width: 375px) {
                max-height: 400px;
            }
        }
    }
}

.nav-link {
    padding: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: var(--common-navBar-textColorNavBarItem) !important;
    background-color: transparent;
    position: relative;
    margin: 0 0 0 35px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    white-space: nowrap;

    @media (max-width: 1200px) {
        margin: 0;
        padding: 30px 24px !important;
        width: 100%;
        line-height: 10px;
        // &::after {
        //     content: " ";
        //     float: right;
        //     border: solid #ffffff;
        //     border-width: 0 2px 2px 0;
        //     padding: 3px;
        //     transform: rotate(-45deg);
        // }
    }

    @media (min-width: 1200px) {
        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 51%;
            right: 51%;
            bottom: -5px;
            background: #707070;
            height: 4px;
            border-radius: 2px;
            transition-property: left, right;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
        }
    }

    &:global(.active)::before,
    &:hover::before {
        left: 0;
        right: 0;
    }
}
