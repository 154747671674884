.button-wallet-wrap {
    background: var(--common-backgroundColorGray);
    font-weight: 700;
    font-size: 18px;
    color: var(--common-wallet-textColor);
    border-radius: 12px;
    padding: 12px;
    border: 1px solid var(--common-wallet-borderColorButton);
    cursor: pointer;
}
