.card-content {
    padding: 20px;

    // .auctionTime {
    //     margin-bottom: 20px;
    //     .time {
    //         display: flex;
    //         justify-content: space-around;
    //         margin-bottom: 15px;

    //         .oneTime {

    //             .number {
    //                 font-weight: bold;
    //                 font-size: 18px;
    //                 line-height: 150%;
    //                 color: #FFFFFF;
    //             }

    //             .text {
    //                 font-weight: 600;
    //                 font-size: 14px;
    //                 line-height: 150%;
    //                 color: #FFFFFF;
    //             }
    //         }
    //     }
    // }

    .content {
        display: flex;

        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
        }

        .left {
            width: 48%;

            @media (max-width: 600px) {
                width: 100%;
            }

            .title {
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                color: #f6f7fb;
                margin-bottom: 15px;

                .name {
                    color: #00ffc2;
                    text-transform: uppercase;
                }
            }

            .avatar {
                display: flex;
                align-items: center;
                margin-top: 20px;

                .img {
                    border-radius: 50px;
                }

                .name {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 150%;
                    color: #f6f7fb;
                    margin: 0px 15px;
                    width: 55%;
                }
            }

            .price {
                // margin-left: 15px;
                font-size: 14px;

                .coin {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 150%;
                    background: linear-gradient(to right, #00ffc2 0%, #14a2f2 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .dollar {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 150%;
                    color: #f6f7fb;
                }

                .value {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 150%;
                    color: #aeb6ce;
                }
            }
        }

        .right {
            width: 52%;

            @media (max-width: 600px) {
                width: 100%;
                margin-top: 20px;
            }

            .title {
                font-weight: bold;
                font-size: 14px;
                line-height: 150%;
                color: #f6f7fb;
            }

            .time {
                display: flex;
                justify-content: center;
                margin-top: 15px;

                .input {
                    height: 40px;
                    width: 100%;
                    border: 1px solid #fafafa;
                    box-sizing: border-box;
                    border-radius: 6px;
                    padding: 8px 15px;
                    font-weight: normal;
                    font-size: 16px;
                    color: #fafafa;
                    background-color: transparent;
                    margin-bottom: 5px;
                }

                .oneTime {
                    width: 25%;

                    .number {
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 150%;
                        color: #ffffff;
                    }

                    .text {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 150%;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .inputGroup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .input {
            height: 40px;
            width: 48%;
            border: 1px solid #fafafa;
            box-sizing: border-box;
            border-radius: 6px;
            padding: 8px 15px;
            font-weight: normal;
            font-size: 16px;
            color: #fafafa;
            background-color: transparent;
            // margin-bottom: 15px;
        }
    }

    .submit {
        height: 40px;
        width: 100%;
        background: #1a87ff;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        border: none;
        margin-top: 20px;
    }

    .finish {
        width: 100%;
        .title {
            font-weight: 500;
            font-size: 20px;
            color: #f6f7fb;
            margin-bottom: 20px;
        }

        .button {
            height: 40px;
            background: #1a87ff;
            border-radius: 4px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            color: #ffffff;
            border: none;
        }

        .table-header {
            border: 1px solid rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(14px);
            --webkit-backdrop-filter: blur(14px);
            border-radius: 8px 8px 0px 0px;
            background: rgba(38, 38, 38, 0.4);

            .cell {
                background: rgba(38, 38, 38, 0.4);
                font-weight: bold;
                font-size: 14px;
                color: #f6f7fb;
                border: 1px solid rgba(255, 255, 255, 0.1);
            }

            .sort {
                display: flex;
                align-items: center;

                .group {
                    margin-left: 7px;
                    display: flex;
                    flex-direction: column;

                    img {
                        height: 4px;
                    }

                    img:hover {
                        cursor: pointer;
                    }

                    img:first-child {
                        margin-bottom: 2px;
                    }

                    .hidden {
                        display: none;
                    }
                }
            }
        }

        .table-row {
            border: 1px solid rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(14px);
            --webkit-backdrop-filter: blur(14px);
            background: rgba(46, 46, 46, 0.4);

            .cell {
                background: rgba(46, 46, 46, 0.4);
                border: 1px solid rgba(255, 255, 255, 0.1);
                font-weight: normal;
                font-size: 14px;
                color: #f6f7fb;
            }

            .price {
                background: -webkit-linear-gradient(rgba(0, 255, 194, 1), rgba(20, 162, 242, 1));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .table-row:hover {
            cursor: pointer;
            background: rgba(38, 38, 38, 0.4);

            .cell {
                background: rgba(38, 38, 38, 0.4);
            }

            .price {
                background: -webkit-linear-gradient(rgba(0, 255, 194, 1), rgba(20, 162, 242, 1));
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}
