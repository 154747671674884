.error {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 15px;

    &-icon {
        display: inline-block;
        margin-right: 4px;
    }

    &-text {
        display: inline-block;
        color: #F3574F;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
    }

    &-custom {
        margin-bottom: 0px;
    }
}