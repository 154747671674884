.no-result {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--common-blockItemBackgroundColor);

    &-icon {
        margin-bottom: 15px;
        color: var(--common-textColor);
    }

    &-text {
        color: var(--common-textColorGray1);
        font-style: normal;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.4px;
        font-weight: 700;
        font-size: 18px;
    }
}
