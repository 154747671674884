.pagination {
    display: inline-block;
}

.page-link {
    padding: 0 10px;
    border: none;
    background-color: transparent;

    &-icon {
        width: auto;
        height: 10px;
        color: #aeb6ce;
    }

    &-text {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        color: #aeb6ce;
        vertical-align: middle;
    }

    &-active {
        .page-link-text {
            background: linear-gradient(135deg, #32fbfc 0%, #3214f2 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
