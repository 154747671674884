.connect-wallet {
    @media (min-width: 1200px) {
        margin-left: 16px;
        // height: 80px;
        // margin: 0 auto;
    }
    .box-verify-topup {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        .warning {
            width: calc(50% - 4px);
            background: var(--common-wallet-headerBackgroundColor);
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 6px 10px;
            font-size: 14px;
            font-weight: 600;
            justify-content: center;
            cursor: pointer;
        }
    }
    .topup {
        color: var(--common-textColor);
        width: calc(50% - 4px);
        background: var(--common-wallet-headerBackgroundColor);
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 6px 10px;
        font-size: 14px;
        font-weight: 600;
        justify-content: center;
        cursor: pointer;
        svg {
            margin-right: 4px;
        }
    }
    .profile {
        height: 60px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 1200px) {
            height: auto;
        }

        &-button {
            height: 44px;
            background: var(--common-backgroundColor);
            border-radius: 23px;
            display: flex;
            justify-content: center;
            align-items: center;

            &-border {
                border: 2px solid #009cfd;
            }

            @media (max-width: 1200px) {
                margin: 0 16px;
                background: var(--common-navBar-backgroundColorItemNavbar);
                border-radius: 22px;
                width: 44px;
                height: 44px;
            }
        }

        .hover-section {
            position: absolute;
            top: 60px;
            right: 0px;
            width: 370px;
            height: 100px;
            clip-path: polygon(-5% 37%, 88% -9%, 100% 33%);
        }

        &-dropdown {
            .avatar {
                width: 32px;
                height: 32px;
                object-fit: cover;
                border-radius: 50%;
                border: none;
                cursor: pointer;
                @media (max-width: 428px) {
                    width: 44px;
                    height: 44px;
                }
            }

            .avatar-erc20 {
                width: 32px;
                height: 32px;
                object-fit: cover;
                border-radius: 50%;
                cursor: pointer;

                @media (max-width: 428px) {
                    width: 44px;
                    height: 44px;
                }
            }

            &-content {
                position: absolute;
                width: 370px;
                // height: 300px;
                top: 60px;
                left: auto;
                right: 0;
                background: var(--common-wallet-dropdownBackgroundColor);
                border: 1px solid var(--common-wallet-borderColor);
                box-sizing: border-box;
                box-shadow: -5px -5px 12px 20px rgba(35, 34, 57, 0.2), 6px 6px 12px 20px rgba(18, 17, 32, 0.1);
                border-radius: 8px;

                .header {
                    width: 100%;
                    height: 54px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    background: var(--common-wallet-headerBackgroundColor);
                    border-radius: 8px 8px 0 0;
                    border-bottom: 1px solid var(--common-underlineColor);
                    padding: 0 20px;

                    &-title {
                        font-weight: 600;
                        font-size: 14px;
                        color: var(--common-textColor);
                    }
                }

                .body {
                    .user {
                        padding: 4px 0px 20px 0px;
                        margin-left: 20px;
                        margin-right: 20px;

                        .my-profile {
                            margin-bottom: 16px;
                            display: flex;
                            align-items: center;
                            font-weight: bold;
                            font-size: 20px;
                            color: var(--common-wallet-darkThemeTextColor);
                            cursor: pointer;

                            .icon {
                                margin-right: 12px;
                                width: 18px;
                                height: 18px;
                                color: var(--common-wallet-darkThemeTextColor);
                            }
                        }

                        .darkmode {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;

                            &-title {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &-icon {
                                    margin-right: 12px;
                                    color: var(--common-wallet-darkThemeTextColor);
                                    width: 18px;
                                    height: 18px;
                                }

                                &-text {
                                    font-family: Source Sans Pro;
                                    font-style: normal;
                                    font-weight: bold;
                                    font-size: 20px;
                                    line-height: 150%;
                                    color: var(--common-wallet-darkThemeTextColor);
                                }
                            }

                            &-toggle {
                                position: relative;
                                display: flex;
                                align-items: center;
                                width: 40px;
                                height: 21px;
                                border-radius: 16px;
                                cursor: pointer;

                                &-dark {
                                    background: #2f80ed;
                                }

                                &-light {
                                    background: #777e90;
                                }

                                &-button {
                                    position: absolute;
                                    width: 17px;
                                    height: 17px;
                                    background: #ffffff;
                                    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
                                    border-radius: 16px;
                                    transition: all 0.5s ease;
                                    left: 7%;

                                    &-active {
                                        left: 53%;
                                    }
                                }
                            }
                        }

                        .wallet-title-wrapper {
                            display: flex;
                            align-items: center;
                            margin-bottom: 8px;

                            .wallet-name {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                font-weight: 600;
                                font-size: 13px;
                                color: var(--common-wallet-darkThemeTextColor);
                                margin-bottom: 10px;

                                &-orai {
                                    height: 10px;
                                    width: 10px;
                                    border-radius: 50%;
                                    background: linear-gradient(99.99deg, #005dfa 0%, #009cfd 98.89%);
                                    margin-right: 6px;
                                }

                                &-bsc {
                                    height: 10px;
                                    width: 10px;
                                    border-radius: 50%;
                                    background: linear-gradient(269.48deg, #dd4c2d 0%, #ed802c 99.55%);
                                    margin-right: 6px;
                                }

                                &-bsc-wallet {
                                    font-weight: 700;
                                    font-size: 16px;
                                    margin-right: 8px;
                                    color: var(--common-textColor);
                                }
                            }

                            .logout {
                                position: absolute;
                                right: 0px;
                                padding-right: 20px;

                                &-icon {
                                    width: 20px;
                                    height: 20px;
                                    color: var(--common-textColor);

                                    &:hover {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        .contact {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;

                            &-name {
                                font-family: Source Sans Pro;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 20px;
                                line-height: 150%;
                                color: var(--common-textColor);
                            }

                            &-address {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: center;
                                margin-top: 5px;

                                &-value {
                                    margin-right: 8px;
                                    font-weight: normal;
                                    font-size: 14px;
                                    color: var(--common-textColor);
                                }

                                &-copy {
                                    color: var(--common-textColor);
                                    cursor: pointer;
                                    max-width: 16px;
                                }
                            }
                        }

                        &-infor {
                            position: relative;
                            // margin-top: 6px;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;

                            .avatar {
                                width: 36px;
                                height: 36px;
                                margin-right: 12px;
                                cursor: pointer;
                                border-radius: 50%;
                            }

                            .avatar-erc20 {
                                width: 36px;
                                height: 36px;
                                margin-right: 12px;
                                border-radius: 50%;
                                border: none;
                            }

                            .balance {
                                width: calc(100% - 72px);
                                &-title {
                                    font-family: Source Sans Pro;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 16px;
                                    color: var(--common-placeholderTextColor);
                                    display: flex;
                                    align-items: center;
                                    span {
                                        white-space: nowrap;
                                        display: inline-block;
                                        max-width: calc(100% - 30px);
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                    img {
                                        margin-left: 5px;
                                    }
                                }

                                &-address > .contact-address-value {
                                    font-weight: 700;
                                    font-size: 18px;
                                    color: var(--common-textColor);
                                }

                                small {
                                    font-family: Source Sans Pro;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 16px !important;
                                    line-height: 1;
                                    background: var(--common-wallet-usdDenomTextColor);
                                    background-clip: text;
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                }
                            }
                        }

                        .connect {
                            &-wallet {
                                height: 50px;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                font-weight: 600;
                                font-size: 16px;
                                color: #f6f7fb;
                                margin: 0 0 8px 0px;
                                border-radius: 8px;
                                padding-left: 12px;

                                &-icon {
                                    height: 32px;
                                    width: 32px;
                                    margin-right: 10px;
                                }

                                &-keplr {
                                    height: 54px !important;
                                }

                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            &-orai {
                                height: 74px;
                                background: var(--common-backgroundColorGray);
                                border: 1px solid var(--common-wallet-borderColorButton);
                                font-weight: 700;
                                font-size: 18px;
                                color: var(--common-wallet-textColor);
                            }

                            &-metamask,
                            &-wallet-connect {
                                height: 54px;
                                background: var(--common-backgroundColorGray);
                                border: 1px solid var(--common-wallet-borderColorButton);
                                font-weight: 700;
                                font-size: 18px;
                                color: var(--common-wallet-textColor);
                            }
                        }
                    }

                    .button-group {
                        padding-bottom: 20px;
                        margin-right: 30px;
                        margin-bottom: 14px;
                        display: flex;
                        justify-content: space-between;

                        .button-claim {
                            background: #db7093;
                            color: #fff;
                            width: 150px;
                            font-weight: 600;
                            text-align: center;
                            border-radius: 25px;
                            cursor: pointer;
                            border: none;
                            height: 50px;
                        }
                        .button-swap-nft {
                            background: #1a87ff;
                            color: #fff;
                            width: 150px;
                            font-weight: 600;
                            text-align: center;
                            border-radius: 25px;
                            cursor: pointer;
                            border: none;
                            height: 50px;
                        }
                    }

                    .button {
                        padding-bottom: 20px;
                        margin-right: 30px;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;

                        &-swap {
                            padding: 8px 57px 8px 58px;
                            margin-right: 10px;
                            width: 150px;
                            background: #1a87ff;
                            border-radius: 4px;
                            font-family: Source Sans Pro;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 150%;
                            color: #ffffff;
                            cursor: pointer;
                        }

                        &-withdraw {
                            padding: 8px 45px 8px 46px;
                            width: 150px;
                            background: #274868;
                            border-radius: 4px;
                            font-family: Source Sans Pro;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 150%;
                            text-align: center;
                            color: #ffffff;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

.price-value-text {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 140%;
}

.warning {
    cursor: pointer;
    &.approve {
        margin-top: 8px;
        > div {
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 6px 10px;
            font-size: 14px;
            font-weight: 600;
            justify-content: center;
            cursor: pointer;
            background: rgba(255, 100, 100, 0.15);
        }
    }
    &-icon {
        margin-right: 4px;
        width: 14px;
        height: 14px;
    }

    &-text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        font-weight: 600;

        &-error {
            color: #ffc700;
        }

        &-success {
            color: #5ee373;
            cursor: default;
        }
    }
}

.wallet-popup {
    opacity: 0;
    visibility: hidden;
}
.revertButton {
    &::before {
        display: none !important;
    }
}

.button-connect-to-discord {
    background: #5865f2;
    border-radius: 8px;
    margin: 8px 0;
    font-weight: 600;
    font-size: 13px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    padding: 6px 0;
    align-items: center;
    cursor: pointer;
    height: 37px;

    .item-icon {
        margin-right: 4px;
        text-align: center;
    }
}

:global(.ant-modal-content) {
    background: var(--common-backgroundColor) !important;
    background-color: var(--common-backgroundColor) !important;
    border: 1px solid rgb(92, 92, 92) !important;
    border-radius: 8px;
    text-align: center;

    .success-connect-discord,
    .disconnect-connect-discord {
        margin: 40px 0 24px 0;
    }

    .text {
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        color: var(--common-textColor);
    }

    .sub-text {
        margin-top: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: var(--common-profile-textColorGray1);
    }
}

:global(.ant-modal-body) {
    padding-bottom: 6px !important;
}

:global(button.ant-btn) {
    align-items: center;
    height: 50px;
    border-radius: 8px;
    width: 100%;
    color: var(--common-textColor);
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 16px;
}

.profile {
    &-button {
        position: relative;

        &-status {
            position: absolute;
            top: 0;
            right: -5px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;

            .warning {
                cursor: pointer;
                &-icon {
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}

.wallet-sub-title {
    color: var(--common-wallet-darkThemeTextColor);
    font-weight: 600;
    font-size: 13px;
}

.menu-wrapper {
    background: var(--common-backgroundColor);
    padding: 12px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    .price-wrapper {
        &-airight,
        &-orai {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;

            .left {
                display: flex;
                align-items: center;
                .icon {
                    margin-right: 10px;
                    width: 40px;
                    height: 40px;
                }

                small {
                    background: var(--common-wallet-usdDenomTextColor);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 700;
                    font-size: 18px !important;
                }
            }

            .top-up {
                padding: 12px;
                font-weight: 600;
                font-size: 13px;
                color: var(--common-textColor);
                background: var(--common-backgroundColorGray);
                border-radius: 8px;
                cursor: pointer;
            }
        }

        &-airight {
            .price-value-text {
                // flex-direction: row-reverse !important;
            }
        }
    }
}

.menu-more-options,
.more-options-bsc-wallet {
    border: 1px solid var(--common-wallet-borderColorButton);
    padding: 16px;
    padding-bottom: unset;
    border-radius: 8px;
    background: var(--common-backgroundColor);
    margin-top: 4px;

    .menu-more-options-item {
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        color: var(--common-textColor);
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        align-items: center;

        .left-wrap {
            display: flex;

            .icon {
                margin-right: 12px;
            }
        }
    }

    .menu-more-options-item.disable {
        opacity: 0.2;
    }
}

.bsc-wallet-wrapper {
    padding: 20px 0px 0px 0px;
    margin-left: 20px;
    margin-right: 20px;

    .container {
        background: var(--common-backgroundColor);
        box-shadow: 0px 1px 5px 2px rgba(13, 16, 22, 0.01), 0px 20px 15px 2px rgba(13, 16, 22, 0.1);
        border-radius: 16px;

        .balance-display {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            background: var(--common-backgroundColor);
            border-radius: 16px;

            .left-wrap {
                display: flex;

                img {
                    margin-right: 10px;
                }

                .balance {
                    &-title {
                        font-weight: 700;
                        font-size: 18px;
                        color: var(--common-textColor);
                    }

                    &-value {
                        font-weight: 600;
                        font-size: 13px;
                        color: #777e90;
                    }
                }
            }

            .top-up {
                padding: 12px;
                font-weight: 600;
                font-size: 13px;
                color: var(--common-textColor);
                background: var(--common-backgroundColorGray);
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

.wrapper-wallet {
    background: var(--common-backgroundColor);
    box-shadow: 0px 1px 5px 2px rgba(13, 16, 22, 0.01), 0px 20px 15px 2px rgba(13, 16, 22, 0.1);
    border-radius: 16px;
}
