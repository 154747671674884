.button {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    cursor: pointer;
    user-select: none;
    border: none;

    &:focus-visible {
        outline: none;
        border: none;
    }

    &-icon {
        display: inline-block;
        margin-right: 8px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &-text {
        display: inline-block;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
    }

    &-warning-icon {
        margin-left: 4px;
        font-size: 18px;
    }

    &-border {
        border: 1px solid rgba(255, 255, 255, 0.3);
    }
    &-gradient {
        padding-left: 20px;
        padding-right: 20px;
        background: linear-gradient(154.49deg, rgba(108, 105, 117, 0.2) 5.35%, rgba(54, 53, 103, 0.2) 83.85%), rgba(49, 48, 54, 0.3);
        border-radius: 8px;
        position: relative;
        transform: perspective(1px) translateZ(0);
        transition-duration: 0.3s;
        transition-property: box-shadow;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);

        &:active,
        &:focus,
        &:hover {
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
        }

        &::before {
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            filter: blur(14px);
            background-color: rgba(255, 255, 255, 0.05);
        }
    }

    &-primary {
        padding-left: 20px;
        padding-right: 20px;
        justify-content: center;
        background: #1a87ff;
        border-color: #1a87ff;
        border-radius: 4px;
    }

    &-danger {
        padding-left: 20px;
        padding-right: 20px;
        justify-content: center;
        background: #f3574f;
        border-color: #f3574f;
        border-radius: 4px;
    }

    &-outline-secondary {
        width: 100%;
        justify-content: center;
        background-color: inherit;
        border: 1px solid #aeb6ce;
        border-radius: 4px;
    }

    &-disabled {
        opacity: 0.5;
    }

    &-fit-content {
        width: auto;
        padding-left: 45px;
        padding-right: 45px;
    }
}
