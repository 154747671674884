.footer {
    .nav {
        padding-left: 0;
    }
    display: block;
    float: left;
    width: 100%;
    background-color: #262626;
}

.top-section {
    padding: 40px 0;
    white-space: nowrap;
    li {
        list-style: none;
    }

    .email {
        margin-bottom: 16px;

        &-function {
            color: #f6f7fb;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
        }

        &-address {
            margin-top: 12px;
            color: #d7d7d7;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
        }
    }

    .address {
        p {
            margin: 0;
            color: #d7d7d7;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
        }
    }

    .community {
        // @media (max-width: 600px) {
        //     display: flex;
        //     flex-wrap: wrap;

        //     & > li {
        //         &:first-child {
        //             width: 100%;
        //         }
        //         margin-right: 20px;
        //     }
        // }
        margin-left: 30px;
    }
}

.bottom-section {
    background-color: #000;
    color: #f6f7fb;
    &-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        @media (min-width: 992px) {
            justify-content: space-between;
            flex-direction: row;
        }

        .copy-right {
            padding: 13px 0 0 0;
            color: #f6f7fb;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 150%;

            @media (min-width: 992px) {
                padding: 13px 0;
            }
        }

        .nav {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            padding: 0;
            margin: 0;
            list-style: none;

            &-item {
            }

            &-link {
                display: inline-block;
                margin-right: 12px;
                padding: 13px 0;
                color: #f6f7fb;
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 150%;
            }
        }
    }
}
