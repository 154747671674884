:global(.ant-modal-header) {
    background: transparent;
    border-bottom: none;
    padding: 20px 30px;
}
:global(.ant-modal-title) {
    color: var(--common-textColor);
    font-weight: bold;
    font-size: 26px;
}
:global(.ant-modal-body) {
    padding: 10px 30px 30px 30px;
}
:global(.ant-modal-close-x) {
    font-size: 20px;
    color: var(--common-textColor);
}
:global(.ant-modal-content) {
    background: var(--common-backgroundColor) !important;
    background-color: var(--common-backgroundColor) !important;
    border: 1px solid rgb(92, 92, 92) !important;
    border-radius: 8px;
}

.wrap-approve {
    .box-img {
        text-align: center;
        padding: 30px 0 15px;
    }
    .text-head {
        font-weight: 700;
        font-size: 24px;
        color: #ff3a3a;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        line-height: 1;
        svg {
            margin-right: 5px;
        }
    }
    .title {
        font-weight: 700;
        font-size: 24px;
        color: var(--common-textColor);
        text-align: center;
        text-transform: uppercase;
    }
    .box-text {
        margin-top: 15px;
        padding: 8px 15px;
        background: var(--common-blockItemBackgroundColor);
        color: var(--common-textColor);
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        border-radius: 6px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        span {
            background: -webkit-linear-gradient(360deg, #26cbff, #176dff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        p {
            margin-bottom: 0;
            color: #ff3a3a;
            margin-top: 7px;
        }
    }
    button {
        outline: none;
        border: none;
        background: linear-gradient(93.94deg, #0044e3 -18.41%, #00a3ff 104.73%) !important;
        height: 50px;
        border-radius: 8px;
        color: #fff !important;
        font-weight: 700;
        font-size: 18px;
        width: 100%;
        margin-top: 30px;
        svg {
            margin-right: 6px;
        }
    }
}
