.background {
    left: 0;
    top: 0;
    width: 99vw;
    height: 100%;
    background-image: url("/assets/images/ComingSoonBackground.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -1;
}

.wrapper {
    height: 99vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 60px;
        text-align: center;
        letter-spacing: 30px;
        color: #ffffff;
    }
}
