.modal {
    position: relative;
    padding-top: 22px;
    padding-bottom: 32px;
    padding-left: 28px;
    padding-right: 28px;
    width: 436px;
    background: var(--common-backgroundColor);
    border-radius: 8px;

    @media (max-width: 428px) {
        width: 100%;
    }

    @media (max-width: 375px) {
        width: 300px;
    }

    @media (max-width: 320px) {
        width: 255px;
    }

    &-header {
        margin-left: 8px;
        width: 100%;
        display: flex;

        .back-icon {
            width: 22px;
            height: 22px;
            cursor: pointer;
            color: var(--common-textColor);
        }

        .close-icon {
            cursor: pointer;
            color: var(--common-textColor);
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .step-image {
            margin-top: -40px;
        }

        .step-title {
            margin-top: -10px;
            margin-bottom: 8px;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 150%;
            text-align: center;
            color: var(--common-textColor);
        }

        .step-description {
            margin-bottom: 8px;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: var(--common-placeholderTextColor);
        }

        .input-email {
            padding: 11px 0px 12px 14px;
            width: 100%;
            height: 50px;
            background: var(--common-blockItemBackgroundColor);
            border: 1px solid var(--common-nftDetails-tableBorderColor);
            border-radius: 8px;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 150%;
            color: var(--common-textColor);

            &::placeholder {
                color: var(--common-placeholderTextColor);
            }
        }

        .code-wrapper {
            margin-top: 8px;
            display: flex;
            align-items: center;

            .input-code {
                margin-right: 8px;
                width: 50px;
                height: 55px;
                background: var(--common-blockItemBackgroundColor);
                border: 1px solid var(--common-nftDetails-tableBorderColor);
                border-radius: 8px;
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 150%;
                text-align: center;
                color: var(--common-textColor);

                &:last-child {
                    margin-right: 0px;
                }

                @media (max-width: 428px) {
                    width: 35px;
                    height: 40px;
                    font-size: 16px;
                }

                &:focus {
                    background: linear-gradient(var(--common-blockItemBackgroundColor), var(--common-blockItemBackgroundColor))
                            padding-box,
                        linear-gradient(135deg, var(--common-gradientTextColor_1) 0%, var(--common-gradientTextColor_2) 100%)
                            border-box;
                    border: 2px solid transparent;
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: textfield;
                }
            }
        }

        .send-state {
            margin-top: 10px;
            display: flex;
            align-items: center;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 150%;
            text-align: center;
            color: var(--common-textColor);

            @media (max-width: 428px) {
                font-size: 14px;
            }

            span {
                background: linear-gradient(93.94deg, #0044e3 -18.41%, #00a3ff 104.73%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                cursor: pointer;
            }
        }

        .button-submit {
            margin-top: 24px;
            width: 100%;
            padding: 12px;
            background: linear-gradient(93.94deg, #0044e3 -18.41%, #00a3ff 104.73%);
            border: none;
            border-radius: 8px;
            font-family: Source Sans Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 150%;
            color: #f6f7fb;
            text-align: center;
            cursor: pointer;

            &:disabled {
                opacity: 0.5;
            }
        }

        .button-wrapper {
            margin-top: 32px;
            width: 100%;
            display: flex;
            align-items: center;
            white-space: nowrap;

            .button-mint-nft {
                width: 50%;
                padding: 12px;
                background: linear-gradient(93.94deg, #0044e3 -18.41%, #00a3ff 104.73%);
                border-radius: 8px;
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 150%;
                color: #f6f7fb;
                text-align: center;
                cursor: pointer;
                border: none;

                @media (max-width: 428px) {
                    font-size: 14px;
                }
            }

            .button-go-to-marketplace {
                margin-right: 12px;
                width: 50%;
                padding: 12px;
                background: #1e1e21;
                border-radius: 8px;
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 150%;
                text-align: center;
                color: #e0e0e0;
                cursor: pointer;
                border: none;

                @media (max-width: 428px) {
                    font-size: 14px;
                }
            }
        }
    }
}

.error-text-custom {
    font-family: Source Sans Pro !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 140% !important;
    color: #ff6464 !important;
}

:global(.MuiTooltip-popper) {
    left: 7px !important;
    background: #fff9e5;
    border: 0.5px solid #dad4c0;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: #918250;
}

:global(.MuiTooltip-tooltip) {
    margin: 0 !important;
    font-family: Source Sans Pro !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 140% !important;
    color: #918250 !important;
    background: transparent !important;
}

:global(.MuiTooltip-arrow) {
    background: transparent;
    color: #cecccd !important;
}
