.pagination {
    display: inline-block;
    cursor: default !important;
}

.page-link {
    margin-right: 17px;
    padding: 0 10px;
    border: none;
    background-color: transparent;

    &:last-child {
        margin-right: 0px;
    }

    &-icon {
        opacity: 0.7;
        width: 44px;
        height: 44px;
        color: var(--common-textColor);
    }

    &-text {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        color: #aeb6ce;
        vertical-align: middle;
    }

    &-active {
        .page-link-text {
            background: linear-gradient(135deg, #32fbfc 0%, #3214f2 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
