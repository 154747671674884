.event {
    margin-right: auto;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 4px;
    border: 2px solid var(--common-usdCurrencyBorderColor);
    box-sizing: border-box;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 150%;
    border-radius: 8px;
    color: var(--common-usdCurrencyTextColor);

    @media (max-width: 768px) {
        padding: 4px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
    }

    @media (max-width: 1024px) {
        margin-right: 0;
    }

    &-buy {
        border: 2px solid #00ecd0;
        color: #00ecd0;
    }

    &-sell {
        border: 2px solid #fba018;
        color: #fba018;
    }

    &-list {
        border: 2px solid #00ecd0;
        color: #00ecd0;
    }

    &-lock {
        border: 2px solid #fba018;
        color: #fba018;
    }

    &-icon {
        display: inline-block;
        margin-right: 8px;
        color: inherit;

        @media (max-width: 768px) {
            margin-right: 6px;
        }
        
    }

    &-text {
        display: inline-block;
        color: inherit;
        text-transform: capitalize;
    }
}
