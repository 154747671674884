.price {
    display: flex;
    align-items: center;

    &-right {
        justify-content: flex-end;
    }

    &-denom-icon {
        margin-right: 4px;
        width: 18px;
        height: 18px;
        color: var(--common-textColor);
    }
}
