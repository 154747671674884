body {
    margin: 0;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 1rem !important;
    background-color: rgb(24 24 24) !important;
    overflow: visible !important;
}

#root {
    width: 100vw;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    /* height: 100vh; */
    -webkit-overflow-scrolling: touch;
}

a {
    text-decoration: none;
    color: #00fff0;
}

a:hover {
    color: rgba(0, 255, 240, 0.8);
}

.price-number {
    color: #d7d7d7;
}

.main-layout {
    width: 100%;
    float: left;
    padding-top: 60px;
    position: relative;
    z-index: 1;
}

.container {
    position: relative;
    max-width: 1240px !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input {
    outline: none;
}

.skeleton-wrapper > span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.skeleton-wrapper .react-loading-skeleton {
    background-color: #34333f;
    background-image: linear-gradient(90deg, #34333f, #3a2017, #34333f);
}

.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
}
.close:hover {
    opacity: 1;
    cursor: pointer;
}
.close:before,
.close:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: #ffffff;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}

.MuiPaper-root {
    background-color: transparent !important;
}
