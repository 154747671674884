.not-found {
  background: #1d1d1d;
  position: relative;
}

.background {
  left: 0;
  top: 0;
  width: 99vw;
  height: 100%;
  background-image: url("/assets/images/ComingSoonBackground.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}

.main-layout {
  min-height: unset;
}

.wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .text {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 60px;
      text-align: center;
      color: #ffffff;
  }

  h1 {
    font-weight: 900;
  }

  h4 {
    opacity: 0.7;
  }

  .back {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-transform: uppercase;
    color: #FFFFFF;
    display: inline-block;
    padding: 8px;
    border: solid 1px;
    background: linear-gradient(
      154.49deg
      , rgba(108, 105, 117, 0.2) 5.35%, rgba(54, 53, 103, 0.2) 83.85%), rgba(29, 28, 34, 0.3);
    border-radius: 4px;
  }
}