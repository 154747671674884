.main {
    min-height: calc(100vh - 154px);
    // background-image: url(../../../assets//images//bg_main.png);
    // background-image: linear-gradient(#2F2461, #0E1F4F);
    background-repeat: no-repeat;
    background-size: cover;
}
.cursor-pointer {
    cursor: pointer;
}
