.topup-modal {
    :global(.ant-modal-header) {
        background: transparent;
        border-bottom: none;
        padding: 20px 30px;
    }
    :global(.ant-modal-title) {
        color: var(--common-textColor);
        font-weight: bold;
        font-size: 26px;
    }
    :global(.ant-modal-body) {
        margin-bottom: 16px;
        padding: 10px 30px 30px 30px;
    }
    :global(.ant-modal-close-x) {
        font-size: 20px;
        color: var(--common-textColor);
    }
    :global(.ant-modal-content) {
        padding: 16px;
        background: var(--common-backgroundColor) !important;
        background-color: var(--common-backgroundColor) !important;
        border: 1px solid rgb(92, 92, 92) !important;
        border-radius: 8px;
    }
}

.wrap-topup {
    .box-item {
        background: var(--common-blockItemBackgroundColor);
        border-radius: 8px;
        padding: 12px 20px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        cursor: pointer;
        box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
        &:last-child {
            margin-bottom: 0;
        }
        .content {
            padding-left: 15px;
            .title {
                font-size: 16px;
                font-weight: 700;
                color: var(--common-textColor);
                line-height: 1;
                margin-bottom: 2px;
            }
            .desc {
                font-size: 14px;
                color: var(--common-textColor);
            }
            .err {
                color: #f2c94c;
            }
        }
    }
}
