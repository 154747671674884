.airi-farming {
    position: fixed;
    text-align: center;
    background: #2250f4;
    color: #ffffff;
    font-weight: 500;
    font-size: 0.9375em;
    padding: 0;
    z-index: 2000;
    width: 100%;

    .airi-farming-text {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 7px 0px;
        font-weight: bold;

        @media (min-width: 576px) {
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }

    .stake-now {
        padding-left: 5px;
    }

    .announcement-bar-close {
        position: static;
        align-self: flex-end;
        margin-right: 16px;
        width: 20px;
        fill: #fff;
        cursor: pointer;

        @media (min-width: 576px) {
            position: absolute;
            right: 0;
        }
    }
}

.navbar {
    height: 70px;
    width: 100%;
    max-width: 100vw;
    z-index: 1000;
    padding-top: 0;
    padding-bottom: 0;
    background: var(--common-navBar-backgroundColorNavbar);
    backdrop-filter: blur(14px);
    -webkit-backdrop-filter: blur(14px);

    :global(.navbar-collapse) {
        background-color: #000000;
        padding: 16px;
        flex-grow: 0;

        @media (min-width: 1200px) {
            margin-left: 65px;
            justify-content: flex-start !important;
            background-color: transparent;
            padding: 0;
        }
    }

    @media (max-width: 600px) {
        :global(.container) {
            padding: 0;
            height: 100%;
            touch-action: none;
        }

        :global(.navbar-brand) {
            padding-left: var(--bs-gutter-x, 0.5rem);
        }

        :global(button) {
            border: none;
        }

        :global(.navbar-nav) {
            align-items: flex-start !important;
            justify-content: flex-start !important;
            height: 100vh;
        }
    }

    @media (max-width: 1200px) {
        :global(.navbar-nav) {
            align-items: flex-start !important;
            justify-content: flex-start !important;
            height: fit-content;
        }
    }

    &-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .navbar-right {
            display: flex;
            margin-right: 16px;
        }
    }

    .navbar-custom {
        min-width: fit-content;
        padding-top: 40px;
        flex-basis: 0%;
        flex-grow: unset;
        background: #1e344d;
        z-index: 1;

        .icon-cancel {
            cursor: pointer;
            margin-top: -40px;
        }
    }

    .navbar-dropdown {
        @media (max-width: 600px) {
            width: 80vw;
            flex-basis: 0%;
            flex-grow: unset;
        }

        @media (max-width: 1200px) {
            width: 60vw;
            flex-basis: 0%;
            flex-grow: unset;
        }

        width: 100%;
        :global(.container) {
            padding: 0;
            height: 100%;
            touch-action: none;
        }
    }
}

.navbar.navbar-light {
    border-bottom: 1px solid #e7eaf2;
}

.navbar-staked {
    top: 78px;

    @media (min-width: 576px) {
        top: 36px;
    }
}

.navbar-brand {
    &-image {
        width: 110px;
        height: 50px;
    }

    &-text {
        @media (min-width: 1200px) {
            margin-right: 16px;
        }

        margin-right: 0;
        margin-left: 12px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        /* identical to box height, or 27px */

        /* White */

        color: #ffffff;
    }
}

.navbar-search {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    margin-top: 16px;
    margin-bottom: 16px;
    background-color: #1998ff;
    border: 1px solid #707070;

    /* BLACK GLASS 3 */

    @media (min-width: 1200px) {
        width: 360px;
        margin-top: 0;
        margin-bottom: 0;
        background: linear-gradient(123.03deg, rgba(0, 0, 0, 0.04) 0%, rgba(54, 53, 103, 0.04) 100%), rgba(0, 0, 0, 0.4);
        opacity: 0.7;
    }

    &::before {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        filter: blur(14px);
        background-color: rgba(255, 255, 255, 0.05);
    }

    /* Note: backdrop-filter has minimal browser support */
    > * {
        z-index: 1;
    }

    border-radius: 8px;

    &-text {
        padding: 4px 20px;
        flex-grow: 1;
        border: none;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
        background-color: transparent;

        &::placeholder {
            color: #ffffff;
        }

        &:focus-visible {
            outline: none;
        }
    }

    &-icon {
        margin-right: 13px;
        color: #ffffff;
        cursor: pointer;
    }
}

.nav-toggle {
    color: rgba(255, 255, 255, 0.55) !important;
    font-size: 32px;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: var(--common-navBar-backgroundColorItemNavbar);
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        box-shadow: none;
    }
}

.image {
    height: 50px;
    width: 114px;
}

.mobile-wallet-dropdown {
    max-width: 100vw;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: var(--common-backgroundColor);
    height: 0px;
    z-index: 999;
    transition: height 0.5s;
    border-radius: 20px 20px 0 0;
    border-top: 1px solid var(--common-navBar-borderColor);

    &-active {
        height: 550px;
    }
}

.mobile-menu-dropdown {
    max-width: 100vw;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: var(--common-backgroundColor);
    height: 0px;
    z-index: 999;
    transition: height 0.5s;
    border-radius: 20px 20px 0 0;
    border-top: 1px solid var(--common-navBar-borderColor);

    &-active {
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.kawaii-market-button {
    cursor: pointer;
}

.nav-link-children-mobile {
    padding: 24px;
    font-weight: bold;
    font-size: 18px;
    color: var(--common-navBar-textColorNavBarItem);

    .nav-link-children-mobile-header {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .title {
            margin-left: 8px;
        }
    }
}

.nav-item-children-mobile {
    padding-top: 29px;
    display: flex;
    align-items: center;
    .icon {
        margin-right: 8px;
    }
}

.nav-item-children-mobile.dark-theme {
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.menu-setting {
    padding: 24px;
    font-weight: bold;
    font-size: 18px;
    color: var(--common-navBar-textColorNavBarItem);

    .darkmode {
        &-toggle {
            position: relative;
            display: flex;
            align-items: center;
            width: 40px;
            height: 21px;
            border-radius: 16px;
            cursor: pointer;

            &-dark {
                background: #2f80ed;
            }

            &-light {
                background: #777e90;
            }

            &-button {
                position: absolute;
                width: 17px;
                height: 17px;
                background: #ffffff;
                box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
                border-radius: 16px;
                transition: all 0.5s ease;
                left: 7%;

                &-active {
                    left: 53%;
                }
            }
        }
    }
}

.footer-navbar-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 60px;
}

.divider {
    height: 1px;
    background: var(--common-navBar-borderColor);
}

.footer-navbar-dropdown-wrapper {
    height: 80px;
}
