.notification {
    &-toggle {
        position: relative;
        width: 44px;
        height: 44px;
        border-radius: 100%;
        background: var(--common-navBar-backgroundColorItemNavbar);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background: var(--common-hoverBackgroundColorGray);
        }

        &-active {
            background: var(--common-hoverBackgroundColorGray);
        }

        &-count {
            position: absolute;
            top: 0;
            right: -5px;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background: linear-gradient(99.99deg, #0056e8 0%, #009cfd 98.89%);
            color: #ffffff;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-icon {
            color: #ffffff;
            width: 20px;
            height: 22px;

            &:hover {
                color: #ffffff;
            }
        }
    }

    &-dropdown {
        position: absolute;
        width: 370px;
        max-width: 100vw;
        // height: 300px;
        top: 60px;
        right: 0;
        background: var(--common-wallet-dropdownBackgroundColor);
        border: 1px solid var(--common-wallet-borderColor);
        box-sizing: border-box;
        box-shadow: -5px -5px 12px 20px rgba(35, 34, 57, 0.2), 6px 6px 12px 20px rgba(18, 17, 32, 0.1);
        border-radius: 8px;

        @media (max-width: 428px) {
            // position: fixed;
            // transition: height 0.5s;
            // overflow: hidden;

            position: absolute;
            height: 550px;
            width: 100%;
            max-width: 100vw;
            top: 42vh;
            right: 0;
            background: var(--common-wallet-dropdownBackgroundColor);
            border: 1px solid var(--common-wallet-borderColor);
            box-sizing: border-box;
            box-shadow: -5px -5px 12px 20px rgba(35, 34, 57, 0.2), 6px 6px 12px 20px rgba(18, 17, 32, 0.1);
            border-radius: 8px;
        }

        .header {
            width: 100%;
            height: 54px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background: var(--common-wallet-headerBackgroundColor);
            border-radius: 8px 8px 0 0;
            border-bottom: 1px solid var(--common-underlineColor);
            padding: 0 20px;

            &-title {
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                color: var(--common-textColor);
            }

            &-mark-read {
                display: flex;
                align-items: center;
                cursor: pointer;
                &-icon {
                    margin-right: 8px;
                }

                &-title {
                    font-family: Source Sans Pro;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    background: linear-gradient(99.99deg, #26cbff 0%, #176dff 98.89%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }

        .body {
            // padding: 13px 9px 40px 20px;
            padding-bottom: 13px;

            .notification-wrapper {
                height: 400px;
                overflow: auto;
                margin-right: 8px;

                .notification-row {
                    padding: 13px 0px 15px 20px;
                    display: flex;
                    align-items: flex-start;
                    cursor: pointer;

                    &:hover {
                        background: var(--common-notificationIconBackgroundHoverColor);
                    }

                    &-unread {
                        background: var(--common-notificationUnreadColor);
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .user-avatar-wrapper {
                        position: relative;
                        margin-right: 9px;
                        width: fit-content;

                        .avatar {
                            width: 46px;
                            height: 46px;
                            border-radius: 100%;
                        }

                        .action-badge {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 18px;
                            height: 18px;
                            border-radius: 100%;
                            background: linear-gradient(99.99deg, #0056e8 0%, #009cfd 98.89%);
                            font-family: Inter;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 150%;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &-icon {
                                width: 12px;
                                height: 12px;
                                color: #ffffff;
                            }

                            &-green {
                                background: linear-gradient(99.99deg, #00c738 0%, #14f500 98.89%);
                            }

                            &-blue {
                                background: linear-gradient(99.99deg, #0056e8 0%, #009cfd 98.89%);
                            }

                            &-orange {
                                background: linear-gradient(99.99deg, #ed6c0e 0%, #fdb600 98.89%);
                            }

                            &-red {
                                background: linear-gradient(99.99deg, #e80000 0%, #ff5555 98.89%);
                            }

                            &-gray {
                                background: linear-gradient(99.99deg, #8a8a8a 0%, #bfbfbf 98.89%);
                            }

                            &-purple {
                                background: linear-gradient(99.99deg, #bc00cc 0%, #da00fd 98.89%);
                            }

                            &-yellow {
                                background: linear-gradient(99.99deg, #ffaf15 0%, #ffd748 98.89%);
                            }
                            &-white {
                                background: #fff;
                            }
                        }
                    }

                    .action {
                        &-title {
                            width: 220px;
                            font-family: Source Sans Pro;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 120%;
                            letter-spacing: 0.02em;
                            color: var(--common-notificationActionTextColor);
                            word-break: break-word;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            p {
                                display: inline;
                                font-weight: 700;
                            }
                        }

                        &-time {
                            font-family: Source Sans Pro;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 13px;
                            line-height: 120%;
                            color: #777e90;
                        }
                    }

                    .more-icon {
                        margin-right: 5px;
                        margin-left: auto;
                        width: 24px;
                        height: 24px;
                        color: var(--common-notificationActionTextColor);
                        cursor: pointer;
                    }
                }

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    margin-top: 8px;
                    margin-bottom: 8px;
                    background: var(--common-scrollBarBackgroundColor);
                    border-radius: 4px;
                }

                &::-webkit-scrollbar-thumb {
                    background: var(--common-placeholderTextColor);
                    border-radius: 4px;
                }
            }
        }
    }
}

.bold-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: var(--common-textColor);
}

.price-text {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    background: linear-gradient(99.99deg, #26cbff 0%, #176dff 98.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.textColorGradient {
    background: -webkit-linear-gradient(99.99deg, #26cbff 0%, #176dff 98.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 14px;
}
