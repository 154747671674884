.transaction-processing-modal {
    :global(.ant-modal-header) {
        background: transparent;
        border-bottom: none;
        padding: 20px 30px;
    }
    :global(.ant-modal-title) {
        color: var(--common-textColor);
        font-weight: bold;
        font-size: 26px;
    }
    :global(.ant-modal-body) {
        padding: 10px 30px 30px 30px !important;
    }
    :global(.ant-modal-close-x) {
        font-size: 20px;
        color: var(--common-textColor);
    }
    :global(.ant-modal-content) {
        background: var(--common-backgroundColor) !important;
        background-color: var(--common-backgroundColor) !important;
        border: 1px solid rgb(92, 92, 92) !important;
        border-radius: 8px;
    }
}

:global(.ant-radio-group) {
    font-size: 16px;
    font-variant: inherit;
    font-feature-settings: inherit;
    width: 100%;
}
:global(.ant-radio-wrapper) {
    margin: 0;
}
:global(.ant-radio-inner) {
    width: 18px;
    height: 18px;
    background-color: var(--common-blockItemBackgroundColor);
    border-color: #1890ff;
    &::after {
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        background: linear-gradient(93.94deg, #0044e3 -18.41%, #00a3ff 104.73%);
    }
}

.wrap-payment {
    .box-amount {
        margin-top: 30px;
        .list-price {
            margin-top: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .p-item {
                background: var(--common-blockItemBackgroundColor);
                border-radius: 6px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                font-size: 16px;
                font-weight: 600;
                width: 90px;
                color: var(--common-textColor);
                cursor: pointer;
                margin-bottom: 5px;
                &.active {
                    border: 2px solid #008cdd;
                }
            }
        }
        .text-err {
            margin-top: 5px;
            color: #ff6464;
            font-size: 14px;
        }
        .box-input {
            position: relative;
            margin-top: 10px;
            input {
                color: var(--common-textColor);
                font-size: 20px;
                padding: 4px 15px;
                line-height: 1;
                width: 100%;
                height: 50px;
                border-radius: 8px;
                border: 1px solid #333642;
                background: var(--common-blockItemBackgroundColor);
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            .info {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                display: flex;
                align-items: center;
                font-size: 20px;
                color: #828282;
                img {
                    margin: 0 4px 0 7px;
                    width: 18px;
                }
                span {
                    font-weight: bold;
                    background: -webkit-linear-gradient(360deg, #26cbff, #176dff);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
    .box-btn {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .box-total {
        margin-top: 20px;
        color: var(--common-textColor);
        .fee {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .f-title {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
                span {
                    font-weight: 600;
                    display: inline-block;
                    margin-left: 3px;
                }
                svg {
                    margin-left: 5px;
                }
            }
            .f-txt {
                font-size: 16px;
                font-weight: 700;
            }
        }
        .total {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #3c404d;
            margin-top: 7px;
            .f-title {
                font-weight: 700;
                font-size: 16px;
            }
            .f-txt {
                font-size: 24px;
                font-weight: 700;
            }
        }
    }
    button {
        outline: none;
        box-shadow: none;
        border: none;
        width: 100%;
        height: 48px;
        border-radius: 6px;
        font-weight: 700;
        font-size: 18px;
        color: var(--common-textColor);
        width: calc(50% - 10px);
        background: var(--common-blockItemBackgroundColor);
        &:last-child {
            background: linear-gradient(93.94deg, #0044e3 -18.41%, #00a3ff 104.73%) !important;
            color: #fff !important;
            &.disable {
                pointer-events: none;
                opacity: 0.6;
            }
        }
    }
    .pay-card {
        margin-top: 25px;
    }
    .title {
        color: var(--common-textColor);
        font-weight: 700;
        font-size: 16px;
        display: flex;
        align-items: center;
        img {
            margin-right: 6px;
        }
    }
    .item {
        cursor: pointer;
        margin-top: 15px;
        padding: 12px 16px;
        border-radius: 8px;
        color: var(--common-textColor);
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background: var(--common-blockItemBackgroundColor);
        position: relative;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        &.active {
            border: 1px solid #008cdd;
        }
        &.disable {
            pointer-events: none;
            cursor: auto;
            opacity: 0.7;
        }
        .left {
            .box-user {
                display: flex;
                align-items: center;
                .avatar {
                    margin-right: 12px;
                    img {
                        width: 55px;
                        height: 55px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                .info {
                    .address {
                        font-size: 15px;
                    }
                    .name {
                        font-weight: 700;
                        font-size: 18px;
                    }
                    .pay-title {
                        color: var(--common-textColor);
                        font-size: 16px;
                        font-weight: 700;
                    }
                    .pay-desc {
                        font-size: 15px;
                        color: var(--common-textColor);
                    }
                    .pay-err {
                        font-size: 15px;
                        color: #ff6464;
                    }
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            .info {
                text-align: right;
                span {
                    display: inline-block;
                    font-size: 15px;
                    margin-bottom: 5px;
                }
                p {
                    margin: 0;
                    font-weight: 700;
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    font-size: 16px;
                    img {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
.wrap-confirm,
.wrap-buy {
    .image {
        text-align: center;
        padding: 15px 0;
    }
    .title {
        text-align: center;
        color: var(--common-textColor);
        font-size: 28px;
        font-weight: 700;
    }
    .desc {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: var(--common-textColor);
    }
    .box-price {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 12px 15px;
        background: var(--common-blockItemBackgroundColor);
        border-radius: 4px;
        color: var(--common-textColor);
        p {
            margin: 0;
            font-weight: 700;
            font-size: 18px;
            display: flex;
            align-items: center;
        }
        .text-head {
            font-weight: 600;
        }
    }
    .box-btn {
        margin-top: 30px;
        button {
            outline: none;
            box-shadow: none;
            border: none;
            border-radius: 6px;
            height: 50px;
            font-weight: bold;
            font-size: 18px;
            min-width: 140px;
            width: 100%;
            padding: 4px 20px;
            &:first-child {
                background: linear-gradient(93.94deg, #0044e3 -18.41%, #00a3ff 104.73%);
                color: #fff;
            }
        }
    }
}
.btn-back {
    margin-bottom: 24px;
    outline: none;
    border: none;
    border-radius: 6px;
    background: var(--common-blockItemBackgroundColor);
    height: 50px;
    margin-top: 15px;
    color: var(--common-textColor);
    font-weight: 700;
    font-size: 18px;
    width: 100%;
}
.wrap-buy {
    .title {
        margin-top: -10px;
    }
    .box-btn {
        button {
            height: 48px;
            width: 100%;
            &:last-child {
                margin-left: 0;
            }
        }
    }
    .top-up {
        text-align: center;
        color: var(--common-textColor);
        font-weight: 700;
        font-size: 16px;
        position: relative;
        margin-top: 20px;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            transform: translateY(-50%);
            background: var(--common-textColor);
            opacity: 0.5;
        }
        span {
            padding: 0 10px;
            background: var(--common-backgroundColor);
            position: relative;
        }
    }
    .btn-card {
        margin-top: 15px;
        cursor: pointer;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        border-radius: 8px;
        background: linear-gradient(99.99deg, #0056e8 0%, #009cfd 98.89%);
        img {
            margin: 0 4px;
        }
        span {
            display: inline-block;
            margin-right: 10px;
        }
    }
    .btn-link {
        margin-top: 12px;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        text-decoration: none;
        height: 64px;
        display: flex;
        border-radius: 8px;
        background: #178d74;
        align-items: center;
        justify-content: center;
        img {
            margin-right: 10px;
        }
    }
}
.wrap-success {
    .box-img {
        text-align: center;
        margin-top: 20px;
        &.loading {
            margin-bottom: 15px;
            img {
                width: 80px;
            }
        }
    }
    .title {
        color: var(--common-textColor);
        text-align: center;
        font-size: 28px;
        font-weight: 700;
    }
    .desc {
        font-size: 16px;
        font-weight: 600;
        color: #bdbdbd;
        text-align: center;
    }
    .box-transaction {
        background: var(--common-blockItemBackgroundColor);
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        padding: 12px 20px;
        margin-top: 10px;
        color: var(--common-textColor);
        .txt {
            font-size: 16px;
            font-weight: 600;
            opacity: 0.8;
            margin-bottom: 7px;
        }
        .status {
            .stt {
                background: #ddffdc;
                border-radius: 4px;
                height: 30px;
                width: 100px;
                text-align: center;
                color: #219653;
                font-size: 17px;
                font-weight: 700;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &.process {
                    color: #ef892f;
                    background: #fff7de;
                }
            }
        }
        .trans {
            text-align: right;
            margin-left: 20px;

            @media (max-width: 428px) {
                margin-left: 10px;
            }

            .copy {
                display: flex;
                align-items: center;
                font-weight: 700;
                font-size: 17px;

                @media (max-width: 428px) {
                    font-size: 14px;
                }

                svg {
                    cursor: pointer;
                    margin-left: 5px;
                    font-size: 18px;
                }
            }
        }
    }
    .btn {
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background: linear-gradient(99.99deg, #0056e8 0%, #009cfd 98.89%);
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        outline: none;
        border: none;
        margin-top: 30px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
