.nav-dropdown-mobile :global(.nav-link),
.nav-link.mobile {
    margin-top: 25px;
    padding: 0 24px !important;
    width: 100%;
    line-height: 10px;
    font-size: 18px;
    font-weight: bold;
}
.nav-link {
    padding: 0;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: var(--common-navBar-textColorNavBarItem);
    background-color: transparent;
    position: relative;
    margin: 0 0 0 35px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    white-space: nowrap;

    @media (max-width: 1200px) {
        margin: 30px 0 0 0;
        padding: 0 24px !important;
        width: 100%;
        line-height: 10px;
        font-size: 18px;
        font-weight: bold;
        // &::after {
        //     content: " ";
        //     float: right;
        //     border: solid #ffffff;
        //     border-width: 0 2px 2px 0;
        //     padding: 3px;
        //     transform: rotate(-45deg);
        // }
    }

    @media (min-width: 1200px) {
        &::before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 51%;
            right: 51%;
            bottom: -5px;
            background: #707070;
            height: 4px;
            border-radius: 2px;
            transition-property: left, right;
            transition-duration: 0.3s;
            transition-timing-function: ease-out;
        }
    }

    &:global(.active)::before,
    &:hover::before {
        left: 0;
        right: 0;
    }
}

.nav-dropdown :global(.nav-link) {
    width: fit-content;
    &::after {
        display: none;
    }
}

.nav-dropdown-desktop {
    position: relative;
    &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff !important;
    }

    :global(.dropdown-toggle) {
        border-bottom: none;
    }

    &:hover {
        .dropdown-menu {
            display: block;
        }
    }

    .dropdown-menu {
        position: absolute;
        top: 42px;
        min-width: 140px;
        display: none;
        // background-color: transparent;
        border: none;
        right: -53px;
        box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
        border-radius: 8px;
        white-space: nowrap;
        background: var(--common-backgroundColor);
        
        padding: 0;

        &:hover {
            display: block;
        }
    }

    .nav-item {
        width: 100%;
        padding: 15px 0px 17px 54px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: var(--common-textColor);
        // background: transparent;
        border-radius: 8px;
        cursor: pointer;

        @media (min-width: 1200px) {
            padding: 12px 39px 12px 16px;
        }

        &:hover {
            background: var(--common-hoverBackgroundColor);
        }

        // &:active {
        //     background: var(--common-nftDetails-activeTabColor);
        //     color: var(--common-activeTabTextColor);
        // }
    }
    &.menu-drop-left {
        .dropdown-menu {
            left: 0;
            right: auto;
        }
    }
}

.nav-dropdown-mobile {
    width: 100%;
    // :global(.dropdown-toggle) {
    //     border-bottom: none;
    // }

    .dropdown-menu {
        top: 42px;
        display: none;
        background-color: transparent;
        border: none;
        right: -30px;
        box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
        border-radius: 8px;
        

        @media (min-width: 1200px) {
            padding: 0px;
            background: var(--common-blockItemBackgroundColor);
        }
    }

    &:hover {
        .dropdown-menu {
            display: block;
        }
    }

    .nav-item {
        width: 100%;
        padding: 15px 0px 17px 54px;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #ffffff !important;
        background: transparent;
        border-radius: 8px;
        cursor: pointer;

        @media (min-width: 1200px) {
            padding: 10px 15px 10px 16px;
        }

        &:hover {
            background: var(--common-hoverBackgroundColor);
        }

        &:active {
            background: var(--common-nftDetails-activeTabColor);
            color: var(--common-activeTabTextColor);
        }
    }
}

.child-dropdown {
    padding-bottom: 20px;
    margin-bottom: -20px;
    display: flex;
    align-items: center;

    &-icon {
        margin-left: 6px;
        color: #ffffff;
    }

    &:hover {
        .dropdown-menu {
            display: block;
        }
    }
}

.more-icon {
    margin-left: 6px;
}

.text-wrapper {
    display: flex;
    align-items: center;
}

.text-wrapper.mobile {
    color: var(--common-navBar-textColorNavBarItem);
    font-size: 18px;
    font-weight: bold;
    width: 100%;
    padding: 24px 24px 0 24px;
    justify-content: space-between;
    margin-bottom: 0;
    line-height: 0;
    display: flex;
    align-items: center;
}
