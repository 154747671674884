.profile-button {
    width: 170px;
    margin-right: 15px;
    padding: 0 15px;
    justify-content: space-between;
}

.noel-left {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
}
.noel-right {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
}
