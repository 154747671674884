.profile-dropdown-content {
    position: absolute;
    width: 100%;
    max-width: 100vw;
    min-width: 280px;
    height: fit-content;
    left: auto;
    box-sizing: border-box;
    box-shadow: -5px -5px 12px 20px rgba(35, 34, 57, 0.2), 6px 6px 12px 20px rgba(18, 17, 32, 0.1);
    border-radius: 20px 20px 0 0;
    height: 100%;

    .header {
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-radius: 20px 20px 0 0;
        padding: 20px;
        border-bottom: 1px solid #dee1eb;

        &-title {
            font-weight: 600;
            font-size: 13px;
            color: var(--common-textColor);
        }
    }

    .body {
        .user {
            padding: 20px 0px 20px 0px;
            margin-left: 20px;
            margin-right: 20px;

            .darkmode {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                &-title {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &-icon {
                        margin-right: 12px;
                        color: var(--common-wallet-darkThemeTextColor);
                        width: 20px;
                        height: 20px;
                    }

                    &-text {
                        font-family: Source Sans Pro;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 150%;
                        color: var(--common-wallet-darkThemeTextColor);
                    }
                }

                &-toggle {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 40px;
                    height: 21px;
                    border-radius: 16px;
                    cursor: pointer;

                    &-dark {
                        background: #2f80ed;
                    }

                    &-light {
                        background: #777e90;
                    }

                    &-button {
                        position: absolute;
                        width: 17px;
                        height: 17px;
                        background: #ffffff;
                        box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
                        border-radius: 16px;
                        transition: all 0.5s ease;
                        left: 7%;

                        &-active {
                            left: 53%;
                        }
                    }
                }
            }

            .wallet-title-wrapper {
                display: flex;
                align-items: center;

                .wallet-name {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-weight: 600;
                    font-size: 13px;
                    color: var(--common-wallet-darkThemeTextColor);
                    margin-bottom: 10px;

                    &-orai {
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                        background: linear-gradient(99.99deg, #005dfa 0%, #009cfd 98.89%);
                        margin-right: 6px;

                        position: relative;
                        border: 4px solid transparent;
                        border-radius: 16px;
                    }

                    &-bsc {
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                        background: linear-gradient(269.48deg, #dd4c2d 0%, #ed802c 99.55%);
                        margin-right: 6px;
                    }

                    &-bsc-wallet {
                        font-weight: 700;
                        font-size: 16px;
                        margin-right: 8px;
                        color: var(--common-textColor);
                    }
                }

                .logout {
                    position: absolute;
                    right: 0px;
                    padding-right: 20px;

                    &-icon {
                        width: 20px;
                        height: 20px;
                        color: var(--common-textColor);

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .contact {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                &-name {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 150%;
                    color: var(--common-textColor);
                }

                &-address {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-top: 5px;

                    &-value {
                        margin-right: 8px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 140%;
                        color: var(--common-textColor);
                    }

                    &-copy {
                        color: var(--common-textColor);
                        cursor: pointer;
                        max-width: 16px;
                    }
                }
            }

            &-infor {
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                .avatar {
                    width: 36px;
                    height: 36px;
                    margin-right: 12px;
                    cursor: pointer;
                    border-radius: 50%;
                }

                .avatar-erc20 {
                    width: 36px;
                    height: 36px;
                    margin-right: 12px;
                    border-radius: 50%;
                    border: none;
                }

                .balance {
                    width: calc(100% - 72px);
                    &-title {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 16px;
                        color: var(--common-placeholderTextColor);
                        display: flex;
                        align-items: center;
                        span {
                            white-space: nowrap;
                            display: inline-block;
                            max-width: calc(100% - 30px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        img {
                            margin-left: 5px;
                        }
                    }

                    &-address > .contact-address-value {
                        font-weight: 700;
                        font-size: 18px;
                        color: var(--common-textColor);
                    }

                    &-value {
                        background: linear-gradient(99.99deg, #005dfa 0%, #009cfd 98.89%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-family: Source Sans Pro;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 130%;
                        letter-spacing: 0.02em;
                    }

                    small {
                        font-family: Source Sans Pro;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px !important;
                        line-height: 1;
                        background: var(--common-wallet-usdDenomTextColor);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }

            .connect {
                &-wallet {
                    display: flex;
                    padding-left: 12px;
                    margin: 0 0 15px 0px;
                    height: 54px;
                    flex-direction: row;
                    align-items: center;
                    border-radius: 8px;
                    font-weight: 700;
                    font-size: 18px;
                    color: var(--common-wallet-textColor);

                    &-keplr {
                        background: var(--common-backgroundColorGray) !important;
                        border :1px solid var(--common-wallet-borderColorButton);
                    }

                    &-icon {
                        height: 36px;
                        width: 36px;
                        margin-right: 10px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }


                &-orai {
                    background: linear-gradient(268.95deg, #0173ec 0%, #0171f5 100%);
                }

                &-metamask,
                &-wallet-connect {
                    height: 54px;
                    background: var(--common-backgroundColorGray);
                    border: 1px solid var(--common-wallet-borderColorButton);
                    font-weight: 700;
                    font-size: 18px;
                    color: var(--common-wallet-textColor);
                }
            }
        }

        .button-group {
            padding-bottom: 20px;
            margin-right: 30px;
            margin-bottom: 14px;
            display: flex;
            justify-content: space-between;

            .button-claim {
                background: #db7093;
                color: #fff;
                width: 150px;
                font-weight: 600;
                text-align: center;
                border-radius: 25px;
                cursor: pointer;
                border: none;
                height: 50px;
            }
            .button-swap-nft {
                background: #1a87ff;
                color: #fff;
                width: 150px;
                font-weight: 600;
                text-align: center;
                border-radius: 25px;
                cursor: pointer;
                border: none;
                height: 50px;
            }
        }

        .button {
            padding-bottom: 20px;
            margin-right: 30px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            &-swap {
                padding: 8px 57px 8px 58px;
                margin-right: 10px;
                width: 150px;
                background: #1a87ff;
                border-radius: 4px;
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                color: #ffffff;
                cursor: pointer;
            }

            &-withdraw {
                padding: 8px 45px 8px 46px;
                width: 150px;
                background: #274868;
                border-radius: 4px;
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                text-align: center;
                color: #ffffff;
                cursor: pointer;
            }
        }
    }

    .divider {
        background: var(--common-borderColor);
        width: 100%;
        height: 1px;
    }

    .wallet-sub-title {
        color: var(--common-wallet-darkThemeTextColor);
        font-weight: 600;
        font-size: 13px;
    }
}

.box-verify-topup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    .warning {
        width: calc(50% - 4px);
        background: var(--common-wallet-headerBackgroundColor);
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 6px 10px;
        font-size: 14px;
        font-weight: 600;
        justify-content: center;
        cursor: pointer;
    }
}
.topup {
    color: var(--common-textColor);
    width: calc(50% - 4px);
    background: var(--common-wallet-headerBackgroundColor);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    cursor: pointer;
    svg {
        margin-right: 4px;
    }
}

.button-connect-to-discord {
    background: #5865f2;
    border-radius: 4px;
    margin: 8px 0;
    font-weight: 600;
    font-size: 13px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    padding: 6px 0;
    align-items: center;
    cursor: pointer;

    .item-icon {
        margin-right: 4px;
        text-align: center;
    }
}

:global(.ant-modal-content) {
    background: var(--common-backgroundColor) !important;
    background-color: var(--common-backgroundColor) !important;
    border: 1px solid rgb(92, 92, 92) !important;
    border-radius: 8px;
    text-align: center;

    .success-connect-discord,
    .disconnect-connect-discord {
        margin: 40px 0 24px 0;
    }

    .text {
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        color: var(--common-textColor);
    }

    .sub-text {
        margin-top: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: var(--common-profile-textColorGray1);
    }
}

:global(.ant-modal-body) {
    // Impact to others ant modal
    // padding-bottom: 6px !important;
}

:global(button.ant-btn) {
    align-items: center;
    height: 50px;
    border-radius: 8px;
    width: 100%;
    color: var(--common-textColor);
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 16px;
}

.warning {
    cursor: pointer;
    &.approve {
        margin-top: 8px;
        > div {
            border-radius: 4px;
            display: flex;
            align-items: center;
            padding: 6px 10px;
            font-size: 14px;
            font-weight: 600;
            justify-content: center;
            cursor: pointer;
            background: rgba(255, 100, 100, 0.15);
        }
    }
    &-icon {
        margin-right: 4px;
        width: 14px;
        height: 14px;
    }

    &-text {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        font-weight: 600;

        &-error {
            color: #ffc700;
        }

        &-success {
            color: #5ee373;
            cursor: default;
        }
    }
}

.menu-wrapper {
    background: var(--common-backgroundColor);
    padding: 12px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    .price-wrapper {
        &-airight,
        &-orai {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;

            .left {
                display: flex;
                align-items: center;
                .icon {
                    margin-right: 10px;
                    width: 40px;
                    height: 40px;
                }

                small {
                    background: var(--common-wallet-usdDenomTextColor);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-weight: 700;
                    font-size: 18px !important;
                }
            }

            .top-up {
                padding: 12px;
                font-weight: 600;
                font-size: 13px;
                color: var(--common-textColor);
                background: var(--common-backgroundColorGray);
                border-radius: 8px;
                cursor: pointer;
            }
        }

        &-airight {
            .price-value-text {
                // flex-direction: row-reverse !important;
            }
        }
    }
}

.menu-more-options,
.more-options-bsc-wallet {
    border: 1px solid #bdc3d3;
    padding: 16px;
    padding-bottom: unset;
    border-radius: 8px;
    background: var(--common-backgroundColor);
    margin-top: 4px;

    .menu-more-options-item {
        font-weight: 700;
        font-size: 16px;
        cursor: pointer;
        color: var(--common-textColor);
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        align-items: center;

        .left-wrap {
            display: flex;

            .icon {
                margin-right: 12px;
            }
        }
    }

    .menu-more-options-item.disable {
        opacity: 0.2;
    }
}

.bsc-wallet-wrapper {
    padding: 20px 0px 20px 0px;
    margin-left: 20px;
    margin-right: 20px;

    .container {
        background: var(--common-backgroundColor);
        box-shadow: 0px 1px 5px 2px rgba(13, 16, 22, 0.01), 0px 20px 15px 2px rgba(13, 16, 22, 0.1);
        border-radius: 16px;

        .balance-display {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            border-radius: 12px;
            background: var(--common-backgroundColor);

            .left-wrap {
                display: flex;

                img {
                    margin-right: 10px;
                }

                .balance {
                    &-title {
                        font-weight: 700;
                        font-size: 18px;
                        color: var(--common-textColor);
                    }

                    &-value {
                        font-weight: 600;
                        font-size: 13px;
                        color: #777e90;
                    }
                }
            }

            .top-up {
                padding: 12px;
                font-weight: 600;
                font-size: 13px;
                color: var(--common-textColor);
                background: var(--common-backgroundColorGray);
                border-radius: 8px;
                cursor: pointer;
            }
        }
    }
}

.wrapper-wallet {
    background: var(--common-backgroundColor);
    box-shadow: 0px 1px 5px 2px rgba(13, 16, 22, 0.01), 0px 20px 15px 2px rgba(13, 16, 22, 0.1);
    border-radius: 16px;
}

.price-value-text {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 140%;
}
