.countDown {
    .time {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .oneTime {

            .number {
                font-weight: bold;
                font-size: 18px;
                line-height: 150%;
                color: #FFFFFF;
            }

            .text {
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                color: #FFFFFF;
            }
        }
    }
}