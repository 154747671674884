.price-bnb-display {
    &-currency {
        font-weight: 700;
        font-size: 18px;
        color: var(--common-textColor);
    }

    &-bnb {
        font-weight: 600;
        font-size: 13px;
        color: #777e90;
    }
}
