.loading-modal {
    border-radius: 8px;
    padding: 10px;
    max-width: 370px;
    z-index: 9999;

    :global(.modal-content) {
        width: 370px;
        background: #2C3943;
        border-radius: 8px;
    }

    .modal-body {
        background: #2C3943;
        border-radius: 8px;
        padding: 10px;
        background: #2C3943;

        .top-body {
            text-align: right;

            .icon-cancel {
                color: #f3574f;
                cursor: pointer;
            }
        }

        .center-body {
            margin: 20px;
            text-align: center;

            .icn-spinner {
                -webkit-transform: translateZ(0);
                -ms-transform: translateZ(0);
                transform: translateZ(0);
                -webkit-animation: spin-animation 1.6s infinite linear;
                animation: spin-animation 1.6s infinite linear;
                display: inline-block;
            }

            @keyframes spin-animation {
                0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }

            @-webkit-keyframes spin-animation {
                0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
        }

        .bottom-body {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;

            .title {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 150%;
                text-align: center;
                color: #f6f7fb;
            }

            .content {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                text-align: center;
                color: #f6f7fb;
                margin-top: 5px;
                margin-bottom: 10px;
            }

            .MuiButton-root {
                text-transform: none !important;
                background-color: #1a87ff;
                color: #fafafa;
                width: 70%;
            }

            .buy-token-airi {
                width: 70%;
                padding: 6px 10px 6px 10px;
                margin: 20px 15% 0px 15%;
                text-align: center;
                background: linear-gradient(135deg, #f2c314 0%, #fc3832 100%);
                border-radius: 4px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            
                &-text {
                    font-family: Source Sans Pro;
                    line-height: 150%;
                    color: #FFFFFF;
                }
            
                &-icon {
                    margin-left: 7px;
                }
            }
        }
    }
}
