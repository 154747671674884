.plain-nav {
    @media (max-width: 960px) {
        padding-left: 0;
    }

    &-item {
        a {
            text-decoration: none;
            color: #efefef;
        }
    }

    &-title {
        display: inline-block;
        margin-bottom: 10px;
        color: #F6F7FB;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 150%;
    }

    &-link {
        display: inline-block;
        margin-bottom: 10px;
        color: #D7D7D7;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
    }

    &-icon {
        margin-right: 5px;
        color: #D7D7D7;
    }

    &-icon-text {
        color: #D7D7D7 !important;
    }
}
.break-white {
    white-space: normal;
}