.main-layout {
    background-color: #0f1d2d;

    .nft-gallery-detail {
        padding: 50px 0px 70px 0px;
        display: flex;
        color: #f6f7fb;

        @media (max-width: 600px) {
            display: flex;
            flex-direction: column;
        }

        .img-left {
            width: 50%;
            margin-right: 5%;
            // padding: 70px 0px;
            border: 1px solid #4c6595;
            box-sizing: border-box;
            border-radius: 16px;
            height: 635px;

            @media (max-width: 600px) {
                width: 100%;
                height: 300px;
                margin: 0px;
                // padding: 30px 0px;
            }

            .preview-img {
                width: 1200px;
                height: 1200px;
            }
        }

        .info-right {
            width: 45%;

            @media (max-width: 600px) {
                width: 100%;
            }

            .title {
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: bold;
                font-size: 31px;
                line-height: 130%;
                letter-spacing: -0.02em;
                color: #f6f7fb;
                text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 20px 0px;
            }

            .price {
                display: flex;
                align-items: center;

                .coin {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 150%;
                    background: linear-gradient(to right, #00ffc2 0%, #14a2f2 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .dollar {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 150%;
                    color: #aeb6ce;
                    margin-left: 20px;
                }
            }

            .description {
                font-weight: normal;
                font-size: 16px;
                line-height: 150%;
                color: #f6f7fb;
                order: 1;
                margin: 15px 0px;
                width: 88%;
            }

            .owner {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 20px;

                .create {
                    width: 50%;

                    .subtitle {
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 150%;
                        color: #aeb6ce;
                        margin: 10px 0px;
                    }

                    .avatar {
                        display: flex;
                        align-items: center;

                        .img {
                            border-radius: 50px;
                        }

                        .name {
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 150%;
                            color: #f6f7fb;
                            margin: 0px 15px;
                            width: 55%;
                        }
                    }
                }
            }

            .card {
            }
        }
    }
}

// style for nft card (auction, buy, withdraw)

.nft-card {
    width: 100%;
    height: fit-content;
    background: linear-gradient(105.9deg, #003079 0%, #00229c 100%);
    border-radius: 16px;
    margin-top: 45px;
    padding: 10px;

    :global(.nav-tabs) {
        display: flex;
        border-bottom: 1px solid #646464;
    }

    :global(.nav-tabs .nav-link) {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: #aeb6ce;
        text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        transition: none;
        line-height: 150%;
        margin-bottom: 0px;
    }

    :global(.nav-tabs .nav-link:focus) {
        border-color: transparent;
    }

    :global(.nav-tabs .nav-link:hover) {
        border-color: transparent;
    }

    :global(.nav-tabs .nav-link.active) {
        color: #ffffff;
        background-color: initial;
        border-color: transparent;
        border-bottom: 1px solid #ffffff;
    }

    :global(.nav-tabs .nav-link.active:focus) {
        outline: none;
    }

    :global(.nav-tabs .nav-link.active:focus-visible) {
        outline: none;
    }

    :global(.fade:not(.show)) {
        opacity: 1;
    }
}

.nft-detail__tabs {
    :global(.nav-tabs) {
        margin-left: 12px;
        padding-left: 0px;
        border-bottom: 1px solid #646464;
    }

    :global(.nav-link) {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        color: #aeb6ce;
        border: none;
    }

    :global(.nav-link.active) {
        background-color: transparent;
        text-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        opacity: 1;
        border: none;
        border-bottom: 1px solid #ffffff !important;

        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        color: #ffffff;
    }

    :global(.nav-link:hover) {
        color: #f6f7fb;
        // border-bottom: 1px solid #181818;
        border-color: transparent;
    }

    :global(.nav-link:focus) {
        outline: none;
    }
}

.nft-detail__tab-detail {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
}

.nft-detail__tab {
    nav {
        // border-bottom: 1px solid #646464;
    }

    a {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 150%;
        color: #aeb6ce;
    }
}
