.alert-box {
    position: absolute;
    right: 30px;
    top: 65px;
    min-width: 150px;
    width: fit-content;
    border-radius: 8px;
    z-index: 9999;
    background: #780206; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to right,
        #151a3d,
        #4d3b3b
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to right,
        #151a3d,
        #4d3b3b
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    &-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 8px 16px;
    }

    &-body {
        padding: 8px 16px;
    }
}

.title {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &-icon {
        display: inline-block;
        width: auto;
        height: 14px;
        margin-right: 8px;
        vertical-align: baseline;

        &-success {
            color: #28a745;
        }

        &-error {
            color: #dc3545;
        }
    }

    &-text {
        word-break: break-word;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #f6f7fb;
    }
}

.close {
    position: relative;
    top: -8px;
    margin-left: 16px;
    display: inline-block;
    font-size: 18px;
    color: #f6f7fb;
    cursor: pointer;
    user-select: none;
}

.message {
    text-align: left;
    word-break: break-word;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #f6f7fb;
}
