.transaction-history {
    width: 100%;

    border: 1px solid #004687;
    border-radius: 8px;
    border-collapse: collapse;
    overflow: hidden;

    th {
        padding: 20px;
        border: 1px solid #004687;
        background-color: #002265;
        color: #f6f7fb;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        white-space: nowrap;
    }

    td {
        padding: 30px 20px;
        border: 1px solid #004687;
        background: #00246a;
        white-space: nowrap;
    }
}

.tx-hash {
    color: #f6f7fb;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
}

.price {
    background: linear-gradient(135deg, #00ffc2 0%, #14a2f2 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;

    small {
        background: linear-gradient(135deg, #00ffc2 0%, #14a2f2 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
    }
}

.time {
    color: #f6f7fb;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
}

.pagination-section {
    width: 100%;
    margin: auto;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
}

.avatar-item {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .avatar-picture {
        margin-right: 6px;
        width: 24px;
        height: 24px;
        object-fit: cover;
        border-radius: 100%;
    }

    .avatar-username {
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #ffffff;
        z-index: 1;
    }
}
