.price {
    width: fit-content;
    border-radius: 8px;
    max-height: 65px;

    &-value {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        font-style: inherit;
        color: var(--common-priceTextColor);
        // border-radius: 8px;
        // background: linear-gradient(var(--common-blockItemBackgroundColor), var(--common-blockItemBackgroundColor)) padding-box,
        //     linear-gradient(135deg, var(--common-gradientTextColor_1) 0%, var(--common-gradientTextColor_2) 100%) border-box;
        // padding: 4px 8px 4px 8px;
        // border: 2px solid transparent;

        &-text {
            // background: linear-gradient(135deg, var(--common-gradientTextColor_1) 0%, var(--common-gradientTextColor_2) 100%);
            // background-clip: text;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // white-space: nowrap;
            // line-height: 1;

            &-reverse {
                display: flex;
                flex-direction: column-reverse;
            }
        }
    }
}

.font-size-normal {
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
}

.none-border-background-style {
    background: none;
    border: none;
    padding: 0;
}
